import { Grid, Box, Modal } from '@mui/material'

const CategoryModal = ({ open, onClose, yatzyPoints, category, updateScore, playerIndex, placement, numberOfPlayers, onTabChange, setIsGameAction }) => {
    const points = yatzyPoints.find((point) => point.type === category)
  
    const handleSelect = (value) => {
      updateScore({
        playerIndex,
        scoreType: placement,
        categoryName: category,
        value
      })
      onClose()
    }
  
    return (
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="category-modal-title"
        aria-describedby="category-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', sm: 400 },
            maxWidth: '400px',
            bgcolor: '#1a1625',
            border: '1px solid rgba(255,255,255,0.1)',
            borderRadius: '24px',
            boxShadow: 24,
            p: { xs: 2, sm: 4 },
            color: 'white',
          }}
        >
          <h2 id="category-modal-title" style={{ 
            fontSize: { xs: '1.2em', sm: '1.5em' },
            fontWeight: 'bold',
            color: '#8b5cf6',
            marginBottom: '8px',
            textAlign: 'center'
          }}>
            {points?.prettyName}
          </h2>
          <p id="category-modal-description" style={{
            color: 'rgba(255,255,255,0.7)',
            fontSize: { xs: '0.9em', sm: '1em' },
            lineHeight: '1.6',
            maxWidth: '300px',
            margin: '0 auto',
            textAlign: 'center'
          }}>
            {points?.description}
          </p>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            {points?.values.map((value, index) => (
              <Grid item xs={6} key={index}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '40px',
                    border: '1px solid rgba(139,92,246,0.5)',
                    color: '#8b5cf6',
                    backgroundColor: 'rgba(139,92,246,0.1)',
                    fontSize: { xs: '16px', sm: '18px' },
                    borderRadius: '12px',
                    cursor: 'pointer',
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      backgroundColor: '#8b5cf6',
                      color: 'white',
                      transform: 'translateY(-1px)',
                    }
                  }}
                  onClick={() => handleSelect(value)}
                >
                  {value}
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Modal>
    )
}

export default CategoryModal