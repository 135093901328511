// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Normal skygge */
}

/* Standard (contained) */
.button.contained {
    background-color: var(--main-button-color);
    color: var(--secondary-font-color);
    border: none;
}

.button.contained:hover {
    opacity: 0.6;
}

.button.contained:active {
    transform: scale(0.95);
    background-color: var(--active-button-color);
    opacity: 0.9;
}

/* Outline variant */
.button.outlined {
    background-color: transparent;
    color: var(--primary-font-color);
    border: 2px solid var(--main-button-color);
}

.button.outlined:hover {
    opacity: 0.6;
}

.button.outlined:active {
    transform: scale(0.95);
    background-color: var(--active-button-color);
    opacity: 0.9;
}`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,gCAAgC;IAChC,0CAA0C,EAAE,kBAAkB;AAClE;;AAEA,yBAAyB;AACzB;IACI,0CAA0C;IAC1C,kCAAkC;IAClC,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,4CAA4C;IAC5C,YAAY;AAChB;;AAEA,oBAAoB;AACpB;IACI,6BAA6B;IAC7B,gCAAgC;IAChC,0CAA0C;AAC9C;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,4CAA4C;IAC5C,YAAY;AAChB","sourcesContent":[".button {\n    padding: 10px 20px;\n    border-radius: 8px;\n    font-size: 16px;\n    cursor: pointer;\n    transition: all 0.3s ease-in-out;\n    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Normal skygge */\n}\n\n/* Standard (contained) */\n.button.contained {\n    background-color: var(--main-button-color);\n    color: var(--secondary-font-color);\n    border: none;\n}\n\n.button.contained:hover {\n    opacity: 0.6;\n}\n\n.button.contained:active {\n    transform: scale(0.95);\n    background-color: var(--active-button-color);\n    opacity: 0.9;\n}\n\n/* Outline variant */\n.button.outlined {\n    background-color: transparent;\n    color: var(--primary-font-color);\n    border: 2px solid var(--main-button-color);\n}\n\n.button.outlined:hover {\n    opacity: 0.6;\n}\n\n.button.outlined:active {\n    transform: scale(0.95);\n    background-color: var(--active-button-color);\n    opacity: 0.9;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
