
export const yatzyPoints = [
    { 
        type: 'Ones', 
        prettyName: `1'ere`,
        values: [1, 2, 3, 4, 5],
    },
    {
        type: 'Twos',
        prettyName: `2'ere`,
        values: [2, 4, 6, 8, 10],
    },
    {
        type: 'Threes',
        prettyName: `3'ere`,
        values: [3, 6, 9, 12, 15],
    },
    {
        type: 'Fours',
        prettyName: `4'ere`,
        values: [4, 8, 12, 16, 20],
    },
    {
        type: 'Fives',
        prettyName: `5'ere`,
        values: [5, 10, 15, 20, 25]
    },
    {
        type: 'Sixes',
        prettyName: `6'ere`,
        values: [6, 12, 18, 24, 30],
    },
    {
        type: 'OnePair',
        prettyName: '1 Par',
        values: [2, 4, 6, 8, 10, 12],
    },
    {
        type: 'TwoPairs',
        prettyName: '2 Par',
        values: [6, 8, 10, 12, 14, 16, 18, 20, 22],
    },
    {
        type: 'ThreeOfAKind',
        prettyName: '3 ens',
        values: [3, 6, 9, 12, 15, 18],
    },
    {
        type: 'FourOfAKind',
        prettyName: '4 ens',
        values: [4, 8, 12, 16, 20, 24],
    },
    {
        type: 'SmallStraight',
        prettyName: 'Lille Straight',
        values: [15],
    },
    {
        type: 'LargeStraight',
        prettyName: 'Stor Straight',
        values: [20],
    },
    {
        type: 'FullHouse',
        prettyName: 'Hus',
        values: [7, 8, 9, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 26, 27, 28],
    },
    {
        type: 'Chance',
        prettyName: 'Chance',
        values: [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
    },
    {
        type: 'Yatzy',
        prettyName: 'Yatzy',
        values: [50],
    }
]