import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { Grid, TextField, IconButton, Box, Tabs, Tab } from '@mui/material'
import { RemoveCircleOutline, CloseOutlined } from '@mui/icons-material'
import Spacer from '../../components/Spacer/Spacer'
import Settings from './Settings'
import CategoryModal from './CategoryModal'
import { FirstCategory, SecondCategory } from './YatzyProperties'
import { yatzyPoints } from './YatzyPoints'

import './Yatzy.styl'

const YatzyGame = () => {
  const [players, setPlayers] = useState([
    { name: 'Spiller 1', firstCategoryScores: {}, secondCategoryScores: {}, disabledCategories: {} },
  ])
  const [numberOfPlayers, setNumberOfPlayers] = useState(1)
  const [gameType, setGameType] = useState('5 Terninger')
  const [tab, setTab] = useState(0)
  const [showCategoryModal, setShowCategoryModal] = useState(false)
  const [category, setCategory] = useState('')
  const [placement, setPlacement] = useState('')

  // Update player scores
  const updateScore = useCallback((playerIndex, placement, category, value) => {
    setPlayers((prevPlayers) => {
      const newPlayers = [...prevPlayers]
      const scoreType = placement === 'first' ? 'firstCategoryScores' : 'secondCategoryScores'
      newPlayers[playerIndex][scoreType][category] = value
      return newPlayers
    })
  }, [])

  // Disable category for either first or second category
  const disableCategory = useCallback((playerIndex, scoreType, category) => {
    setPlayers((prevPlayers) => {
      const newPlayers = [...prevPlayers];
      newPlayers[playerIndex].disabledCategories[category] = true;
      newPlayers[playerIndex][`${scoreType}CategoryScores`][category] = 0;
      return newPlayers;
    });
  }, []);

  // Remove disabled category
  const removeDisabledCategory = useCallback((playerIndex, category) => {
    setPlayers((prevPlayers) => {
      const newPlayers = [...prevPlayers]
      delete newPlayers[playerIndex].disabledCategories[category]
      return newPlayers
    })
  }, [])

  // Update player count
  useEffect(() => {
    setPlayers(
      Array.from({ length: numberOfPlayers }, (_, index) => ({
        name: `Spiller ${index + 1}`,
        firstCategoryScores: {},
        secondCategoryScores: {},
        disabledCategories: {},
      }))
    )
  }, [numberOfPlayers])

  // Handle player name change
  const handlePlayerNameChange = (e, playerIndex) => {
    const newPlayers = [...players]
    newPlayers[playerIndex].name = e.target.value
    setPlayers(newPlayers)
  }

  // Calculate scores
  const calculateScore = useCallback((scores) => {
    return Object.values(scores)
      .filter((value) => typeof value === 'number')
      .reduce((sum, value) => sum + value, 0)
  }, [])

  const calculateTotal = useCallback(
    (player) => {
      const firstCategoryTotal = calculateScore(player.firstCategoryScores)
      const bonus = firstCategoryTotal >= 63 ? 50 : 0
      const secondCategoryTotal = calculateScore(player.secondCategoryScores)
      return firstCategoryTotal + bonus + secondCategoryTotal
    },
    [calculateScore]
  )

  // Render the score table
  const renderCategoryRows = useCallback(
    (categories, scoreType) => {
      return categories.map((category) => (
        <tr key={category.categoryName}>
          <td>{category.prettyName}</td>
          <td>{`(${category.maxValue})`}</td>
          <td>
            <div
              style={{
                width: '50px',
                height: '30px',
                border: '1px solid black',
                borderRadius: 14,
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                opacity: players[tab].disabledCategories[category.categoryName] ? 0.3 : 1,
              }}
              onClick={() => {
                if (!players[tab].disabledCategories[category.categoryName]) {
                  setShowCategoryModal(true)
                  setCategory(category.categoryName)
                  setPlacement(scoreType)
                }
              }}
            >
              {players[tab].disabledCategories[category.categoryName]
                ? '-'
                : players[tab][`${scoreType}CategoryScores`][category.categoryName] || ''}
            </div>
          </td>
          <td>
            {players[tab].disabledCategories[category.categoryName] ? (
              <IconButton onClick={() => removeDisabledCategory(tab, category.categoryName)}>
                <CloseOutlined />
              </IconButton>
            ) : (
              <IconButton onClick={() => disableCategory(tab, scoreType, category.categoryName)}>
                <RemoveCircleOutline />
              </IconButton>
            )}
          </td>
        </tr>
      ))
    },
    [players, tab, disableCategory]
  )

  return (
    <div>
      <Spacer xl />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tab}
            onChange={(event, newValue) => setTab(newValue)}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            {players.map((player, index) => (
              <Tab key={index} value={index} label={player.name} />
            ))}
          </Tabs>
        </Box>

        <div>
          {players[tab] && (
            <Grid item xs={12}>
              <Spacer />
              <Grid item sx={{ display: 'flex', justifyContent: 'center' }} xs={12}>
                <table>
                  <thead>
                    <tr>
                      <th>Maximum Point</th>
                      <th></th>
                      <th>Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderCategoryRows(Object.values(FirstCategory), 'first')}
                    <tr>
                      <td>Sum</td>
                      <td>(min. 63)</td>
                      <td>{calculateScore(players[tab].firstCategoryScores)}</td>
                    </tr>
                    <tr>
                      <td>Bonus</td>
                      <td>50</td>
                      <td>{calculateScore(players[tab].firstCategoryScores) >= 63 ? 50 : 0}</td>
                    </tr>
                    {renderCategoryRows(Object.values(SecondCategory), 'second')}
                    <tr>
                      <td>Total</td>
                      <td></td>
                      <td>{calculateTotal(players[tab])}</td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
              <Spacer />
              <TextField
                size="small"
                variant="standard"
                label="Navn"
                value={players[tab].name}
                onChange={(e) => handlePlayerNameChange(e, tab)}
              />
              <Spacer />
            </Grid>
          )}
        </div>
      </Box>

      <Spacer l />
      <Settings numberOfPlayers={numberOfPlayers} setNumberOfPlayers={setNumberOfPlayers} gameType={gameType} setGameType={setGameType} />
      <Spacer l />

      <CategoryModal
        open={showCategoryModal}
        onClose={() => setShowCategoryModal(false)}
        yatzyPoints={yatzyPoints}
        category={category}
        onSelect={(value) => {
          updateScore(tab, placement, category, value)
          setShowCategoryModal(false)
        }}
      />
    </div>
  )
}

export default YatzyGame
