import './Button.css'

const Button = ({title, onClick, variant = 'contained', color = 'primary'}) => {
    let buttonStyle
    if (color === 'secondary') {
        buttonStyle = {
            '--main-button-color': 'var(--secondary-button-color)',
        }
    }
    if (color === 'danger' && variant === 'outlined') {
        buttonStyle = {
            '--main-button-color': 'var(--danger-button-color)',
            '--primary-font-color': 'var(--danger-button-color)'
        }
    }
    return (
        <button
            className={`button ${variant}`} // Tilføjer variant som ekstra klasse
            onClick={onClick}
            style={buttonStyle}
        >
            {title}   
        </button>
    )
}

export default Button