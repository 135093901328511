export const FirstCategory = [
  {
    prettyName: `1'ere`,
    categoryName: 'Ones',
    maxValues: { fiveDice: 5, sixDice: 6 },
    disabled: false
  },
  {
    prettyName: `2'ere`,
    categoryName: 'Twos',
    maxValues: { fiveDice: 10, sixDice: 12 },
    disabled: false
  },
  {
    prettyName: `3'ere`,
    categoryName: 'Threes',
    maxValues: { fiveDice: 15, sixDice: 18 },
    disabled: false
  },
  {
    prettyName: `4'ere`,
    categoryName: 'Fours',
    maxValues: { fiveDice: 20, sixDice: 24 },
    disabled: false
  },
  {
    prettyName: `5'ere`,
    categoryName: 'Fives',
    maxValues: { fiveDice: 25, sixDice: 30 },
    disabled: false
  },
  {
    prettyName: `6'ere`,
    categoryName: 'Sixes',
    maxValues: { fiveDice: 30, sixDice: 36 },
    disabled: false
  },
]

export const SecondCategory = [
  {
    prettyName: '1 Par',
    categoryName: 'OnePair',
    maxValues: { fiveDice: 12, sixDice: 12 },
    disabled: false
  },
  {
    prettyName: '2 Par',
    categoryName: 'TwoPairs',
    maxValues: { fiveDice: 22, sixDice: 22 },
    disabled: false
  },
  {
    prettyName: '3 Par',
    categoryName: 'ThreePairs',
    maxValues: { fiveDice: null, sixDice: 30 },
    disabled: false
  },
  {
    prettyName: '3 ens',
    categoryName: 'ThreeOfAKind',
    maxValues: { fiveDice: 18, sixDice: 24 },
    disabled: false
  },
  {
    prettyName: '4 ens',
    categoryName: 'FourOfAKind',
    maxValues: { fiveDice: 24, sixDice: 30 },
    disabled: false
  },
  {
    prettyName: '2x3 ens',
    categoryName: 'TwoTimesThreeOfAKind',  
    maxValues: { fiveDice: null, sixDice: 33 }, 
    disabled: false
  },
  {
    prettyName: 'Lille Straight',
    categoryName: 'SmallStraight',
    maxValues: { fiveDice: 15, sixDice: 15 },
    disabled: false
  },
  {
    prettyName: 'Stor Straight',
    categoryName: 'LargeStraight',
    maxValues: { fiveDice: 20, sixDice: 20 },
    disabled: false
  },
  {
    prettyName: 'Royal',
    categoryName: 'royal',
    maxValues: { fiveDice: null, sixDice: 30 },
    disabled: false
  },
  {
    prettyName: 'Hus',
    categoryName: 'FullHouse',
    maxValues: { fiveDice: 28, sixDice: 28 },
    disabled: false
  },
  {
    prettyName: 'Chance',
    categoryName: 'Chance',
    maxValues: { fiveDice: 30, sixDice: 36 },
    disabled: false
  },
  {
    prettyName: 'Yatzy',
    categoryName: 'Yatzy',
    maxValues: { fiveDice: 50, sixDice: 50 },
    disabled: false
  }
]
