import React, { useEffect, useState, useCallback } from 'react'
import { Grid, TextField, IconButton, Box, Tabs, Tab } from '@mui/material'
import { RemoveCircleOutline, CloseOutlined } from '@mui/icons-material'
import Spacer from '../../components/Spacer/Spacer'
import Settings from './Settings/Settings'
import CategoryModal from './CategoryModal'
import { FirstCategory, SecondCategory } from './YatzyProperties'
import { yatzyPoints } from './YatzyPoints'

const YatzyGame = () => {
  const [players, setPlayers] = useState([
    { name: 'Spiller 1', firstCategoryScores: {}, secondCategoryScores: {}, disabledCategories: {} },
  ])
  const [numberOfPlayers, setNumberOfPlayers] = useState(1)
  const [gameType, setGameType] = useState('fiveDice') // Default to fiveDice
  const [minValueForBonus, setMinValueForBonus] = useState(63)
  const [tab, setTab] = useState(0)
  const [showCategoryModal, setShowCategoryModal] = useState(false)
  const [category, setCategory] = useState('')
  const [placement, setPlacement] = useState('')

  // Update player count when numberOfPlayers changes
  useEffect(() => {
    setPlayers(
      Array.from({ length: numberOfPlayers }, (_, index) => ({
        name: `Spiller ${index + 1}`,
        firstCategoryScores: {},
        secondCategoryScores: {},
        disabledCategories: {},
      }))
    )
  }, [numberOfPlayers])

  useEffect(() => {
    if (gameType === 'sixDice') {
      setMinValueForBonus(84)
    } else {
      setMinValueForBonus(63)
    }
  }, [gameType])

  // Function to filter categories based on gameType
  const getFilteredCategories = (categories) => {
    return categories.filter(category => {
      return category.maxValues[gameType] !== null
    })
  }

  // Calculate score
  const calculateScore = (scoreObject) => {
    return Object.values(scoreObject).reduce((acc, value) => acc + (value || 0), 0)
  }

  // Calculate total score
  const calculateTotal = (player) => {
    let nextScore
    nextScore = calculateScore(player.firstCategoryScores) + calculateScore(player.secondCategoryScores)
    
    // Check if eligible for bonus
    if (calculateScore(player.firstCategoryScores) >= minValueForBonus) {
      nextScore = nextScore + 50
    }
    
    return nextScore
  }

  // Handle player name change
  const handlePlayerNameChange = (e, index) => {
    const newPlayers = [...players]
    newPlayers[index].name = e.target.value
    setPlayers(newPlayers)
  }

  // Update player score
  const updateScore = ({playerIndex, scoreType, categoryName, value}) => {
    const newPlayers = [...players]
    newPlayers[playerIndex][`${scoreType}CategoryScores`][categoryName] = value
    setPlayers(newPlayers)
  }

  // Disable a category
  const disableCategory = (playerIndex, scoreType, categoryName) => {
    const newPlayers = [...players]
    newPlayers[playerIndex].disabledCategories[categoryName] = true
    setPlayers(newPlayers)
    updateScore({playerIndex, scoreType, categoryName, value: 0})
  }

  // Remove a disabled category
  const removeDisabledCategory = (playerIndex, categoryName) => {
    const newPlayers = [...players]
    delete newPlayers[playerIndex].disabledCategories[categoryName]
    setPlayers(newPlayers)
  }

  function resetGameKeepNames({ players }) {
    const resetPlayers = players.map(player => ({
      name: player.name,
      firstCategoryScores: {},
      secondCategoryScores: {},
      disabledCategories: {},
    }))
  
    setPlayers(resetPlayers)
  }

  // Render the score table
  const renderCategoryRows = useCallback(
    (categories, scoreType, isFirstCategory = false) => {
      return getFilteredCategories(categories, isFirstCategory).map((category) => (
        <tr key={category.categoryName}>
          <td>{category.prettyName}</td>
          <td>{`(${category.maxValues[gameType]})`}</td>
          <td>
            <div
              style={{
                width: '50px',
                height: '30px',
                border: '1px solid black',
                borderRadius: 14,
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                opacity: players[tab].disabledCategories[category.categoryName] ? 0.3 : 1,
              }}
              onClick={() => {
                if (!players[tab].disabledCategories[category.categoryName]) {
                  setShowCategoryModal(true)
                  setCategory(category.categoryName)
                  setPlacement(scoreType)
                }
              }}
            >
              {players[tab].disabledCategories[category.categoryName]
                ? '-'
                : players[tab][`${scoreType}CategoryScores`][category.categoryName] || ''}
            </div>
          </td>
          <td>
            {players[tab].disabledCategories[category.categoryName] ? (
              <IconButton onClick={() => removeDisabledCategory(tab, category.categoryName)}>
                <CloseOutlined />
              </IconButton>
            ) : (
              <IconButton onClick={() => disableCategory(tab, scoreType, category.categoryName)}>
                <RemoveCircleOutline />
              </IconButton>
            )}
          </td>
        </tr>
      ))
    },
    [players, tab, gameType]
  )

  return (
    <div>

      {/* Fixed header with Tabs */}
      <div style={{
        position: 'fixed',
        top: 65,
        left: 0,
        right: 0,
        zIndex: 1000, // Ensures the tabs stay on top
        backgroundColor: 'white',
        borderBottom: '1px solid #ccc',
      }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tab}
            onChange={(event, newValue) => setTab(newValue)}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            indicatorColor="secondary"
            textColor="inherit"
          >
            {players.map((player, index) => (
              <Tab 
                key={index} 
                value={index} 
                label={player.name} 
              />
            ))}
          </Tabs>
        </Box>
      </div>
            
      <Spacer size='135px' />
      {/* Main Content Area */}
      <div > {/* Adjusted marginTop for the fixed header */}
        <Grid container>
          <Grid item xs={12}>
            {players[tab] && (
              <Grid item xs={12}>
                <Spacer />
                <Grid item sx={{ display: 'flex', justifyContent: 'center' }} xs={12}>
                  <table>
                    <thead>
                      <tr>
                        <th>Kategori</th>
                        <th></th>
                        <th>Score</th>
                      </tr>
                    </thead>
                    <tbody>
                      {renderCategoryRows(FirstCategory, 'first', true)}
                      <tr>
                        <td>Sum</td>
                        <td>(min. {minValueForBonus})</td>
                        <td>{calculateScore(players[tab].firstCategoryScores)}</td>
                      </tr>
                      <tr>
                        <td>Bonus</td>
                        <td>50</td>
                        <td>{calculateScore(players[tab].firstCategoryScores) >= minValueForBonus ? 50 : 0}</td>
                      </tr>
                      {renderCategoryRows(SecondCategory, 'second')}
                      <tr>
                        <td>Total</td>
                        <td></td>
                        <td>{calculateTotal(players[tab])}</td>
                      </tr>
                    </tbody>
                  </table>
                </Grid>
                <Spacer />
                <TextField
                  size="small"
                  variant="standard"
                  label="Navn"
                  value={players[tab].name}
                  onChange={(e) => handlePlayerNameChange(e, tab)}
                />
                <Spacer />
              </Grid>
            )}
          </Grid>
        </Grid>
  
        <Spacer l />
        <div>
          {/* Settings Section */}
          <Settings 
            numberOfPlayers={numberOfPlayers} 
            setNumberOfPlayers={setNumberOfPlayers} 
            gameType={gameType} 
            setGameType={setGameType} 
            onResetGame={() => resetGameKeepNames({ players })}
          />
        </div>
        <Spacer l />
  
        {/* Category Modal */}
        <CategoryModal
          open={showCategoryModal}
          onClose={() => setShowCategoryModal(false)}
          yatzyPoints={yatzyPoints}
          category={category}
          onSelect={(value) => {
            updateScore({playerIndex: tab, scoreType: placement, categoryName: category, value})
            setShowCategoryModal(false)
          }}
        />
      </div>
    </div>
  )
}

export default YatzyGame