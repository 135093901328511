import React, { useState } from "react"

import Spacer from "../../../components/Spacer/Spacer"
import Button from "../../../components/Button/Button"
// import { Button, ButtonGroup, Grid, IconButton, } from "@mui/material"
import { Grid, IconButton, Paper, Dialog, DialogTitle, DialogContent } from "@mui/material"
import { RemoveCircleOutline, AddCircleOutline } from '@mui/icons-material'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'

const Settings = ({ numberOfPlayers, setNumberOfPlayers, gameType, setGameType, onResetGame }) => {

  const [showSettingsMenu, setShowSettingsMenu] = useState(false)

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '16px' }}>
      <IconButton
        size="large"
        onClick={() => setShowSettingsMenu(!showSettingsMenu)}
        sx={{ 
          color: 'rgba(255,255,255,0.7)',
          '&:hover': {
            color: 'white',
            backgroundColor: 'rgba(255,255,255,0.05)'
          }
        }}
      >
        <SettingsOutlinedIcon fontSize='large' />
      </IconButton> 

      <Button 
        onClick={onResetGame}
        title={'Nulstil Spil'}
        variant="outlined"
        color="danger"
        style={{
          borderColor: 'rgba(239,68,68,0.5)',
          color: 'rgba(239,68,68,0.8)',
          '&:hover': {
            borderColor: '#ef4444',
            color: '#ef4444',
            backgroundColor: 'rgba(239,68,68,0.1)'
          }
        }}
      />
          
      <Dialog 
        open={showSettingsMenu} 
        onClose={() => setShowSettingsMenu(false)}
        PaperProps={{
          sx: {
            backgroundColor: '#1a1625',
            color: 'white',
            border: '1px solid rgba(255,255,255,0.1)',
            borderRadius: '16px'
          }
        }}
      >
        <DialogTitle sx={{ 
          color: 'white',
          borderBottom: '1px solid rgba(255,255,255,0.1)'
        }}>
          Indstillinger
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <div style={{ 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center',
                gap: '20px'
              }}>
                <label style={{ 
                  color: 'rgba(255,255,255,0.7)',
                  fontSize: '1.2em',
                  fontWeight: '500'
                }}>
                  Antal Spillere
                </label>
                
                <div style={{ 
                  display: 'flex',
                  alignItems: 'center',
                  gap: '24px',
                  backgroundColor: 'rgba(255,255,255,0.05)',
                  padding: '12px 24px',
                  borderRadius: '16px',
                  border: '1px solid rgba(255,255,255,0.1)'
                }}>
                  <IconButton
                    size='large'
                    onClick={() => setNumberOfPlayers(numberOfPlayers >= 2 ? numberOfPlayers - 1 : 1)}
                    sx={{ 
                      color: 'rgba(255,255,255,0.7)',
                      '&:hover': {
                        color: 'white',
                        backgroundColor: 'rgba(255,255,255,0.05)'
                      }
                    }}
                  >
                    <RemoveCircleOutline fontSize='large' />
                  </IconButton>
                  <span style={{ 
                    fontSize: '1.4em',
                    color: 'white',
                    minWidth: '40px',
                    textAlign: 'center',
                    fontWeight: '600'
                  }}>
                    {numberOfPlayers}
                  </span>
                  <IconButton 
                    size='large'
                    onClick={() => setNumberOfPlayers(numberOfPlayers + 1)}
                    sx={{ 
                      color: 'rgba(255,255,255,0.7)',
                      '&:hover': {
                        color: 'white',
                        backgroundColor: 'rgba(255,255,255,0.05)'
                      }
                    }}
                  >
                    <AddCircleOutline fontSize='large' />
                  </IconButton>
                </div>
              </div>
            </Grid>
            
            <Grid item xs={12}>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '12px'
              }}>
                <Button 
                  variant={gameType === 'fiveDice' ? "contained" : "outlined"} 
                  onClick={() => setGameType('fiveDice')}
                  title='5 Terninger'
                  style={{
                    backgroundColor: gameType === 'fiveDice' ? 'rgba(139,92,246,0.1)' : 'transparent',
                    borderColor: 'rgba(139,92,246,0.5)',
                    color: gameType === 'fiveDice' ? '#8b5cf6' : 'rgba(255,255,255,0.7)',
                    padding: '12px 24px',
                    fontSize: '1.1em',
                    '&:hover': {
                      backgroundColor: gameType === 'fiveDice' ? 'rgba(139,92,246,0.2)' : 'rgba(139,92,246,0.1)',
                      borderColor: '#8b5cf6',
                      color: '#8b5cf6'
                    }
                  }}
                />
              
                <Button 
                  variant={gameType === 'sixDice' ? "contained" : "outlined"} 
                  onClick={() => setGameType('sixDice')}
                  title='6 Terninger'
                  style={{
                    backgroundColor: gameType === 'sixDice' ? 'rgba(139,92,246,0.1)' : 'transparent',
                    borderColor: 'rgba(139,92,246,0.5)',
                    color: gameType === 'sixDice' ? '#8b5cf6' : 'rgba(255,255,255,0.7)',
                    padding: '12px 24px',
                    fontSize: '1.1em',
                    '&:hover': {
                      backgroundColor: gameType === 'sixDice' ? 'rgba(139,92,246,0.2)' : 'rgba(139,92,246,0.1)',
                      borderColor: '#8b5cf6',
                      color: '#8b5cf6'
                    }
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Settings