import { Grid, Box, Modal } from '@mui/material'
import Spacer from '../../components/Spacer/Spacer'

const CategoryModal = ({ open, onClose, yatzyPoints, category, onSelect }) => {
    const points = yatzyPoints.find((point) => point.type === category)
  
    return (
      <Modal open={open} onClose={onClose} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box
          sx={{
            width: 300,
            height: 200,
            borderRadius: 10,
            backgroundColor: 'white',
            overflowY: 'auto',
            padding: 2,
          }}
        >
          {points && (
            <>
              <div style={{ display: 'flex', justifyContent: 'center', fontSize: '20px', }}>{points.prettyName}</div>
              <Spacer s/>
              <Grid container spacing={1}>
                {points.values.map((value, index) => (
                  <Grid item xs={6} key={index}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '30px',
                        border: '1px solid black',
                        borderRadius: 14,
                      }}
                      onClick={() => onSelect(value)}
                    >
                      {value}
                    </div>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </Box>
      </Modal>
    )
}

export default CategoryModal