const Spacer = ({size = '5px', vertical = true, horizontal, s, m, l, xl, xxl}) => {
   
    if (s) size = '10px'
    if (m) size = '20px'
    if (l) size = '30px'
    if (xl) size = '60px'
    if (xxl) size = '80px'

    let spacerStyle
    if (vertical) {
        spacerStyle = { height: size }
    } else if (horizontal) {
        spacerStyle = { width: size }
    }

    return <div style={spacerStyle} />
    
}

export default Spacer