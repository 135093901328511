import React from "react"

import Spacer from "../../components/Spacer/Spacer"
import { Button, Grid, TextField, IconButton, } from "@mui/material"
import { RemoveCircleOutline, AddCircleOutline, CloseOutlined } from '@mui/icons-material'

const Settings = ({ numberOfPlayers, setNumberOfPlayers, gameType, setGameType }) => {

  return (
    <div>
        <Grid container >
            <Grid item xs={12} >
                <label>
                    Antal Spillere:
                </label>
            </Grid>
            <Grid item xs={12} >
                <div className='yatzy__player-action__buttons' >
                    <IconButton
                        size='large'
                        onClick={() => setNumberOfPlayers(numberOfPlayers >= 2? numberOfPlayers - 1 : 1)}
                    >
                        <RemoveCircleOutline fontSize='large' />
                    </IconButton>
                        {numberOfPlayers}
                    <IconButton 
                        size='large'
                        onClick={() => setNumberOfPlayers(numberOfPlayers + 1)}
                    >
                        <AddCircleOutline fontSize='large' />
                    </IconButton>
                </div>
            </Grid>
        </Grid> 
        <Button 
            variant={gameType === '5 Terninger' ? "contained" : "outlined"} 
            color="primary"
            onClick={() => setGameType('5 Terninger')}
            
        >
            5 Terninger
        </Button>

        {/* <Button 
            variant={gameType === '6 Terninger' ? "contained" : "outlined"} 
            color="primary"
            onClick={() => setGameType('6 Terninger')}
        >
            6 Terninger
        </Button> */}
        <Spacer m />
        {/* <Button 
            variant={"outlined"} 
            color="primary"
        >
            Nustil Spil
        </Button> */}
    </div>
  )
}

export default Settings