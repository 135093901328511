import './Button.css'

const Button = ({title, onClick, variant = 'contained'}) => {

    return (
        <button
            className={`button ${variant}`} // Tilføjer variant som ekstra klasse
            onClick={onClick}
        >
            {title}   
        </button>
    )
}

export default Button