import React from 'react'
import { Box } from '@mui/material'

const MobileLeaderboard = ({ players, calculateTotal }) => {
  const sortedPlayers = [...players].sort((a, b) => calculateTotal(b) - calculateTotal(a))

  return (
    <Box 
      sx={{
        position: 'fixed',
        top: '140px',
        left: 0,
        bottom: 0,
        width: '70px',
        display: { xs: 'flex', md: 'none' },
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '12px',
        overflowY: 'auto',
        zIndex: 0,
        pointerEvents: 'none',
        '& > *': {
          pointerEvents: 'auto'
        },
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        padding: '12px 0'
      }}
    >
      {sortedPlayers.map((player, index) => (
        <Box
          key={player.name}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '2px'
          }}
        >
          <Box
            sx={{
              width: '44px',
              height: '44px',
              borderRadius: '50%',
              backgroundColor: index === 0 
                ? 'linear-gradient(135deg, #8b5cf6 0%, #7c3aed 100%)'
                : 'linear-gradient(135deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.05) 100%)',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              overflow: 'hidden',
              backdropFilter: 'blur(8px)',
              boxShadow: index === 0 
                ? '0 0 20px rgba(139,92,246,0.3)' 
                : '0 4px 12px rgba(0,0,0,0.1)',
              transition: 'all 0.3s ease',
              border: index === 0 
                ? '2px solid #8b5cf6' 
                : '1px solid rgba(255,255,255,0.2)',
              transform: index === 0 ? 'scale(1.1)' : 'scale(1)',
              '&:hover': {
                transform: 'scale(1.1)',
                boxShadow: '0 0 25px rgba(139,92,246,0.4)'
              }
            }}
          >
            {/* Score indicator */}
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                height: `${(calculateTotal(player) / calculateTotal(sortedPlayers[0])) * 100}%`,
                background: index === 0 
                  ? 'linear-gradient(180deg, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0.1) 100%)'
                  : 'linear-gradient(180deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.05) 100%)',
                transition: 'height 0.3s ease'
              }}
            />
            
            {/* Rank emoji */}
            <Box sx={{ 
              fontSize: '20px',
              position: 'relative',
              zIndex: 1,
              filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.2))',
              transform: 'scale(1.1)',
              transition: 'transform 0.3s ease',
              '&:hover': {
                transform: 'scale(1.3)'
              }
            }}>
              {index === 0 ? '👑' : 
               index === 1 ? '🥈' : 
               index === 2 ? '🥉' : 
               index === players.length - 1 ? '💀' : 
               '🎲'}
            </Box>
            
            {/* Score */}
            <Box sx={{ 
              fontSize: '11px',
              fontWeight: 'bold',
              color: 'white',
              position: 'relative',
              zIndex: 1,
              textShadow: '0 2px 4px rgba(0,0,0,0.2)',
              opacity: 0.9
            }}>
              {calculateTotal(player)}
            </Box>
          </Box>

          {/* Player name */}
          <Box sx={{
            fontSize: '10px',
            color: index === 0 ? '#8b5cf6' : 'rgba(255,255,255,0.7)',
            fontWeight: index === 0 ? 'bold' : 'normal',
            textAlign: 'center',
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            textShadow: '0 2px 4px rgba(0,0,0,0.2)',
            transition: 'color 0.3s ease',
            '&:hover': {
              color: '#8b5cf6'
            }
          }}>
            {player.name}
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default MobileLeaderboard 