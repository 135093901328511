import React, { useState } from "react"

import Spacer from "../../../components/Spacer/Spacer"
import Button from "../../../components/Button/Button"
// import { Button, ButtonGroup, Grid, IconButton, } from "@mui/material"
import { Grid, IconButton, } from "@mui/material"
import { RemoveCircleOutline, AddCircleOutline } from '@mui/icons-material'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import SvirrensDialog from "../../../components/SvirrensDialog/SvirrensDialog"

const Settings = ({ numberOfPlayers, setNumberOfPlayers, gameType, setGameType, onResetGame }) => {

  const [showSettingsMenu, setShowSettingsMenu] = useState(false)

  return (
    <div>
        <IconButton
            size="large"
            onClick={() => setShowSettingsMenu(!showSettingsMenu)}
            style={{ color: 'var(--primary-font-color)' }}
        >
            <SettingsOutlinedIcon fontSize='large' />
        </IconButton> 

        <Spacer m />

        <Button 
            onClick={onResetGame}
            title={'Nustil Spil'}
        />
          
           
        <SvirrensDialog
            title='Indstillinger'
            open={showSettingsMenu}
            onClose={() => setShowSettingsMenu(false)}
            content={
                <div style={{ display: 'flex', flexDirection: 'column' }} >
                   
                    <Grid container >
                        <Grid item xs={12} >
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                <label>
                                    Antal Spillere:
                                </label>
                            </div>
                        </Grid>
                        <Grid item xs={12} >
                            <div 
                                className='yatzy__player-action__buttons' 
                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            >
                                <IconButton
                                    size='large'
                                    onClick={() => setNumberOfPlayers(numberOfPlayers >= 2? numberOfPlayers - 1 : 1)}
                                    sx={{ color: 'var(--main-button-color)'}}
                                >
                                    <RemoveCircleOutline fontSize='large' />
                                </IconButton>
                                    {numberOfPlayers}
                                <IconButton 
                                    size='large'
                                    onClick={() => setNumberOfPlayers(numberOfPlayers + 1)}
                                    sx={{ color: 'var(--main-button-color)'}}
                                >
                                    <AddCircleOutline fontSize='large' />
                                </IconButton>
                            </div>
                        </Grid>
                    </Grid> 
                    <Spacer m/>
                        {/* <Button 
                            variant={gameType === 'fiveDice' ? "contained" : "outlined"} 
                            onClick={() => setGameType('fiveDice')}
                        >
                            5 Terninger
                        </Button>

                        <Button 
                            variant={gameType === 'sixDice' ? "contained" : "outlined"} 
                            onClick={() => setGameType('sixDice')}
                        >
                            6 Terninger
                        </Button> */}
                   
                    <Button 
                        variant={gameType === 'fiveDice' ? "contained" : "outlined"} 
                        onClick={() => setGameType('fiveDice')}
                        title='5 Terninger'
                    />
                
                    <Button 
                        variant={gameType === 'sixDice' ? "contained" : "outlined"} 
                        onClick={() => setGameType('sixDice')}
                        title='6 Terninger'
                    />
                            
                </div>
            }
        />
        
    </div>
  )
}

export default Settings