import { Container, Grid, Typography } from '@mui/material'
import Picture from '../images/SvirrensText.png'

function HomePage() {

    return(
        <Container sx={{ marginTop: 8 }} >
            <Grid container columnSpacing={6} >
                <Grid item sm={12} xs={12} >
                    <img src={Picture} alt='svirrens' height={300} />
                </Grid>
                <Grid item sm={12} xs={12} >
                    <Typography variant={'h2'} >
                        Velkommen til Svirrens.com!
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    )
}

export default HomePage