import React, { useEffect, useState, useCallback } from 'react'
import { Grid, TextField, IconButton, Box, Tabs, Tab, Snackbar, Alert, Modal, Button } from '@mui/material'
import { RemoveCircleOutline, CloseOutlined } from '@mui/icons-material'
import Spacer from '../../components/Spacer/Spacer'
import Settings from './Settings/Settings'
import { FirstCategory, SecondCategory } from './YatzyProperties'
import { yatzyPoints } from './YatzyPoints'
import Leaderboard from './components/Leaderboard'
import MobileLeaderboard from './components/MobileLeaderboard'
import ScoreTable from './components/ScoreTable'
import CategoryModal from './components/CategoryModal'
import PlayerTabs from './components/PlayerTabs'

const YatzyGame = () => {
  const [players, setPlayers] = useState([
    { name: 'Spiller 1', firstCategoryScores: {}, secondCategoryScores: {}, disabledCategories: {} },
  ])
  const [numberOfPlayers, setNumberOfPlayers] = useState(1)
  const [gameType, setGameType] = useState('fiveDice') // Default to fiveDice
  const [minValueForBonus, setMinValueForBonus] = useState(63)
  const [tab, setTab] = useState(0)
  const [showCategoryModal, setShowCategoryModal] = useState(false)
  const [category, setCategory] = useState('')
  const [placement, setPlacement] = useState('')
  const [showTurnDialog, setShowTurnDialog] = useState(false)
  const [lastActiveTab, setLastActiveTab] = useState(0)
  const [showGameOverModal, setShowGameOverModal] = useState(false)
  const [isGameAction, setIsGameAction] = useState(false)

  // Update player count when numberOfPlayers changes
  useEffect(() => {
    const currentPlayers = [...players]
    if (numberOfPlayers > currentPlayers.length) {
      // Add new players
      const newPlayers = Array.from({ length: numberOfPlayers - currentPlayers.length }, (_, index) => ({
        name: `Spiller ${currentPlayers.length + index + 1}`,
        firstCategoryScores: {},
        secondCategoryScores: {},
        disabledCategories: {},
      }))
      setPlayers([...currentPlayers, ...newPlayers])
    } else if (numberOfPlayers < currentPlayers.length) {
      // Remove players from the end while preserving existing data
      const updatedPlayers = currentPlayers.slice(0, numberOfPlayers).map(player => ({
        ...player,
        firstCategoryScores: { ...player.firstCategoryScores },
        secondCategoryScores: { ...player.secondCategoryScores },
        disabledCategories: { ...player.disabledCategories }
      }))
      setPlayers(updatedPlayers)
    }
  }, [numberOfPlayers])

  useEffect(() => {
    if (gameType === 'sixDice') {
      setMinValueForBonus(84)
    } else {
      setMinValueForBonus(63)
    }
  }, [gameType])

  // Ensure tab is valid when number of players changes
  useEffect(() => {
    if (tab >= numberOfPlayers) {
      setTab(Math.max(0, numberOfPlayers - 1))
    }
  }, [numberOfPlayers, tab])

  // Add effect to handle turn changes
  useEffect(() => {
    if (tab !== lastActiveTab) {
      if (isGameAction) {
        setShowTurnDialog(true)
      }
      setLastActiveTab(tab)
      setIsGameAction(false)
    }
  }, [tab, lastActiveTab, isGameAction])

  // Function to filter categories based on gameType
  const getFilteredCategories = (categories) => {
    return categories.filter(category => {
      return category.maxValues[gameType] !== null
    })
  }

  // Calculate score
  const calculateScore = (scoreObject) => {
    return Object.values(scoreObject).reduce((acc, value) => acc + (value || 0), 0)
  }

  // Calculate total score
  const calculateTotal = (player) => {
    let nextScore
    nextScore = calculateScore(player.firstCategoryScores) + calculateScore(player.secondCategoryScores)
    
    // Check if eligible for bonus
    if (calculateScore(player.firstCategoryScores) >= minValueForBonus) {
      nextScore = nextScore + 50
    }
    
    return nextScore
  }

  // Handle player name change
  const handlePlayerNameChange = (e, index) => {
    const newPlayers = [...players]
    newPlayers[index].name = e.target.value
    setPlayers(newPlayers)
  }

  // Funktion til at skifte til næste spiller
  const nextPlayer = (playerIndex) => {
    setTimeout(() => {
      const nextTab = (playerIndex + 1) % numberOfPlayers
      setIsGameAction(true)
      setTab(nextTab)
    }, 1000)
  }

  // Update player score
  const updateScore = ({playerIndex, scoreType, categoryName, value}) => {
    const newPlayers = [...players]
    newPlayers[playerIndex][`${scoreType}CategoryScores`][categoryName] = value
    setPlayers(newPlayers)
    nextPlayer(playerIndex)
  }

  // Disable a category
  const disableCategory = (playerIndex, scoreType, categoryName) => {
    const newPlayers = [...players]
    newPlayers[playerIndex].disabledCategories[categoryName] = true
    setPlayers(newPlayers)
    updateScore({playerIndex, scoreType, categoryName, value: 0})
  }

  // Remove a disabled category
  const removeDisabledCategory = (playerIndex, categoryName) => {
    const newPlayers = [...players]
    delete newPlayers[playerIndex].disabledCategories[categoryName]
    setPlayers(newPlayers)
  }

  function resetGameKeepNames({ players }) {
    const resetPlayers = players.map(player => ({
      name: player.name,
      firstCategoryScores: {},
      secondCategoryScores: {},
      disabledCategories: {},
    }))
  
    setPlayers(resetPlayers)
  }

  // Render the score table
  const renderCategoryRows = useCallback(
    (categories, scoreType, isFirstCategory = false) => {
      return getFilteredCategories(categories, isFirstCategory).map((category) => {
        const yatzyPoint = yatzyPoints.find(point => point.type === category.categoryName)
        const hasOnlyOneValue = yatzyPoint?.values.length === 1
        const singleValue = yatzyPoint?.values[0]

        return (
          <tr key={category.categoryName}>
            <td style={{
              padding: '8px 0',
              color: 'rgba(255,255,255,0.9)',
            }}>{category.prettyName}</td>
            <td style={{
              color: 'rgba(255,255,255,0.5)',
              fontSize: '0.9em',
            }}>{`(${category.maxValues[gameType]})`}</td>
            <td style={{ textAlign: 'center' }}>
              <div
                className="score-field"
                style={{
                  width: '50px',
                  height: '30px',
                  color: 'white',
                  fontWeight: 'bold',
                  border: '1px solid rgba(255,255,255,0.2)',
                  borderRadius: '14px',
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  opacity: players[tab].disabledCategories[category.categoryName] ? 0.3 : 1,
                  backgroundColor: 'rgba(255,255,255,0.05)',
                  transition: 'all 0.2s ease',
                  margin: '0 auto',
                  '&:hover': {
                    backgroundColor: 'rgba(139,92,246,0.2)',
                    borderColor: '#8b5cf6',
                  }
                }}
                onClick={() => {
                  if (!players[tab].disabledCategories[category.categoryName]) {
                    if (hasOnlyOneValue) {
                      updateScore({
                        playerIndex: tab,
                        scoreType: isFirstCategory ? 'first' : 'second',
                        categoryName: category.categoryName,
                        value: singleValue
                      })
                    } else {
                      setShowCategoryModal(true)
                      setCategory(category.categoryName)
                      setPlacement(isFirstCategory ? 'first' : 'second')
                    }
                  }
                }}
              >
                {players[tab].disabledCategories[category.categoryName]
                  ? '-'
                  : players[tab][`${scoreType}CategoryScores`][category.categoryName] || ''}
              </div>
            </td>
            <td style={{ textAlign: 'center' }}>
              {players[tab].disabledCategories[category.categoryName] ? (
                <IconButton 
                  onClick={() => removeDisabledCategory(tab, category.categoryName)}
                  sx={{
                    color: 'rgba(255,255,255,0.5)',
                    '&:hover': {
                      color: 'white',
                    }
                  }}
                >
                  <CloseOutlined />
                </IconButton>
              ) : (
                <IconButton 
                  onClick={() => disableCategory(tab, scoreType, category.categoryName)}
                  sx={{
                    color: 'rgba(255,255,255,0.3)',
                    '&:hover': {
                      color: '#ef4444',
                    }
                  }}
                >
                  <RemoveCircleOutline />
                </IconButton>
              )}
            </td>
          </tr>
        )
      })
    },
    [players, tab, gameType, numberOfPlayers, placement]
  )

  // Check if game is over
  const checkGameOver = useCallback(() => {
    const allCategories = [...FirstCategory, ...SecondCategory]
    const filteredCategories = getFilteredCategories(allCategories)
    
    const isGameOver = players.every(player => {
      return filteredCategories.every(category => {
        return player.disabledCategories[category.categoryName] || 
               player.firstCategoryScores[category.categoryName] !== undefined ||
               player.secondCategoryScores[category.categoryName] !== undefined
      })
    })

    if (isGameOver) {
      setShowGameOverModal(true)
    }
  }, [players, gameType])

  // Add effect to check game over after each score update
  useEffect(() => {
    checkGameOver()
  }, [players, checkGameOver])

  return (
    <div style={{
      backgroundColor: '#1a1625',
      minHeight: '100vh',
      color: 'white',
      padding: '20px',
      paddingLeft: { xs: '100px', md: '20px' }
    }}>
      <style>
        {`
          @keyframes pulse {
            0% {
              transform: scale(1);
              opacity: 1;
            }
            50% {
              transform: scale(1.5);
              opacity: 0.5;
            }
            100% {
              transform: scale(1);
              opacity: 1;
            }
          }

          @keyframes ripple {
            0% {
              transform: scale(1);
              opacity: 0.5;
            }
            100% {
              transform: scale(1.5);
              opacity: 0;
            }
          }

          .score-field {
            position: relative;
            overflow: hidden;
          }

          .score-field::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            background: radial-gradient(circle, rgba(139,92,246,0.3) 0%, rgba(139,92,246,0) 70%);
            transform: translate(-50%, -50%) scale(0);
            opacity: 0;
            transition: transform 0.3s ease-out, opacity 0.3s ease-out;
          }

          .score-field:active::after {
            animation: ripple 0.5s ease-out;
          }

          .score-field:active {
            transform: scale(0.95);
            transition: transform 0.1s ease-out;
          }
        `}
      </style>
      
      <Spacer size='100px' />
      
      <PlayerTabs 
        players={players}
        tab={tab}
        setTab={setTab}
        numberOfPlayers={numberOfPlayers}
        handlePlayerNameChange={handlePlayerNameChange}
      />
      
      <Grid container spacing={3} sx={{ marginTop: { md: '10px' } }}>
        <Grid item xs={12} md={3}>
          <Leaderboard 
            players={players}
            calculateTotal={calculateTotal}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <MobileLeaderboard 
            players={players}
            calculateTotal={calculateTotal}
          />
          <ScoreTable 
            players={players}
            tab={tab}
            gameType={gameType}
            minValueForBonus={minValueForBonus}
            renderCategoryRows={renderCategoryRows}
            calculateScore={calculateScore}
            calculateTotal={calculateTotal}
            disableCategory={disableCategory}
            removeDisabledCategory={removeDisabledCategory}
            setShowCategoryModal={setShowCategoryModal}
            setCategory={setCategory}
            setPlacement={setPlacement}
            FirstCategory={FirstCategory}
            SecondCategory={SecondCategory}
          />
        </Grid>
      </Grid>
  
      <Spacer l />
  
      <CategoryModal
        open={showCategoryModal}
        onClose={() => setShowCategoryModal(false)}
        category={category}
        placement={placement}
        updateScore={updateScore}
        playerIndex={tab}
        gameType={gameType}
        yatzyPoints={yatzyPoints}
        numberOfPlayers={numberOfPlayers}
        onTabChange={setTab}
        setIsGameAction={setIsGameAction}
      />

      <Modal
        open={showGameOverModal}
        onClose={() => setShowGameOverModal(false)}
        aria-labelledby="game-over-modal-title"
        aria-describedby="game-over-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: '#1a1625',
            border: '1px solid rgba(255,255,255,0.1)',
            borderRadius: '24px',
            boxShadow: 24,
            p: 4,
            color: 'white',
          }}
        >
          <h2 id="game-over-modal-title" style={{ 
            fontSize: '1.5em',
            fontWeight: 'bold',
            color: '#8b5cf6',
            marginBottom: '8px',
            textAlign: 'center'
          }}>
            Spillet er slut! 🎉
          </h2>
          
          <div style={{
            marginTop: '20px',
            marginBottom: '30px',
            textAlign: 'center'
          }}>
            <div style={{ 
              fontSize: '1.2em',
              marginBottom: '10px',
              color: 'rgba(255,255,255,0.9)'
            }}>
              Vinder:
            </div>
            <div style={{
              fontSize: '1.8em',
              color: '#8b5cf6',
              fontWeight: 'bold'
            }}>
              {players.reduce((prev, current) => 
                calculateTotal(current) > calculateTotal(prev) ? current : prev
              ).name}
            </div>
            <div style={{
              fontSize: '1.2em',
              color: 'rgba(255,255,255,0.7)',
              marginTop: '5px'
            }}>
              med {Math.max(...players.map(p => calculateTotal(p)))} point!
            </div>
          </div>

          <div style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '10px'
          }}>
            <Button
              onClick={() => {
                setShowGameOverModal(false)
                resetGameKeepNames({ players })
              }}
              title="Start nyt spil"
              style={{
                backgroundColor: '#8b5cf6',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#7c3aed'
                }
              }}
            />
            <Button
              onClick={() => setShowGameOverModal(false)}
              title="Luk"
              variant="outlined"
              style={{
                borderColor: 'rgba(255,255,255,0.3)',
                color: 'rgba(255,255,255,0.7)',
                '&:hover': {
                  borderColor: 'white',
                  color: 'white'
                }
              }}
            />
          </div>
        </Box>
      </Modal>

      <Snackbar
        open={showTurnDialog}
        autoHideDuration={4000}
        onClose={() => setShowTurnDialog(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <div
          style={{
            background: 'linear-gradient(135deg, #1a1625 0%, #2d2438 100%)',
            color: 'white',
            border: '1px solid rgba(139,92,246,0.3)',
            borderRadius: '10px',
            boxShadow: '0 4px 20px rgba(0,0,0,0.2)',
            padding: '10px',
            minWidth: '200px',
          }}
        >
          <span style={{ fontSize: '2em', animation: 'pulse 1s infinite' }}>🎲</span>
          <div>
            <div style={{ 
              marginBottom: '4px'
            }}>
              Det er {players[tab].name}'s tur!
            </div>
          </div>
        </div>
      </Snackbar>

      <Settings 
        numberOfPlayers={numberOfPlayers} 
        setNumberOfPlayers={setNumberOfPlayers} 
        gameType={gameType} 
        setGameType={setGameType} 
        onResetGame={() => resetGameKeepNames({ players })}
        players={players}
      />
    </div>
  )
}

export default YatzyGame