import React from 'react'
import { Box, Paper } from '@mui/material'

const Leaderboard = ({ players, calculateTotal }) => {
  return (
    <Paper 
      elevation={3}
      sx={{
        backgroundColor: 'rgba(255,255,255,0.05)',
        borderRadius: '16px',
        padding: '20px',
        color: 'white',
        height: 'fit-content',
        position: 'sticky',
        top: '85px',
        display: { xs: 'none', md: 'block' }
      }}
    >
      <Box sx={{
        fontSize: '1.2em',
        fontWeight: 'bold',
        color: '#8b5cf6',
        marginBottom: '16px',
        textAlign: 'center'
      }}>
        Stilling
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '12px'
      }}>
        {[...players]
          .sort((a, b) => calculateTotal(b) - calculateTotal(a))
          .map((player, index) => (
            <Box
              key={player.name}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
                padding: '8px 12px',
                backgroundColor: index === 0 ? 'rgba(139,92,246,0.1)' : 'rgba(255,255,255,0.05)',
                borderRadius: '8px',
                border: index === 0 ? '1px solid rgba(139,92,246,0.3)' : '1px solid rgba(255,255,255,0.1)',
                transition: 'all 0.2s ease'
              }}
            >
              <Box sx={{
                width: '24px',
                height: '24px',
                backgroundColor: index === 0 ? '#8b5cf6' : 'rgba(255,255,255,0.1)',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '0.9em',
                fontWeight: 'bold'
              }}>
                {index === 0 ? '👑' : 
                 index === 1 ? '🥈' : 
                 index === 2 ? '🥉' : 
                 index === players.length - 1 ? '💀' : 
                 '🎲'}
              </Box>
              <Box sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: '2px'
              }}>
                <Box sx={{
                  fontSize: '0.9em',
                  fontWeight: index === 0 ? 'bold' : 'normal',
                  color: index === 0 ? '#8b5cf6' : 'white'
                }}>
                  {player.name}
                </Box>
                <Box sx={{
                  fontSize: '0.8em',
                  color: 'rgba(255,255,255,0.7)'
                }}>
                  {calculateTotal(player)} point
                </Box>
              </Box>
            </Box>
          ))}
      </Box>
    </Paper>
  )
}

export default Leaderboard 