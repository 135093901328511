// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(13, 15, 28);
}

.App-link {
  color: #5f65c1;
}

:root {
  --primary-font-color: #32323B;
  --secondary-font-color: #E0E0E0;
  --main-background-color: #FFFFFF;
  --main-button-color: #32323B;
  --hover-button-color: #505060;
  --active-button-color: #282832;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,sBAAsB;AACxB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,6BAA6B;EAC7B,+BAA+B;EAC/B,gCAAgC;EAChC,4BAA4B;EAC5B,6BAA6B;EAC7B,8BAA8B;AAChC","sourcesContent":[".App {\n  text-align: center;\n}\n\n.App-header {\n  background-color: #ffffff;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: rgb(13, 15, 28);\n}\n\n.App-link {\n  color: #5f65c1;\n}\n\n:root {\n  --primary-font-color: #32323B;\n  --secondary-font-color: #E0E0E0;\n  --main-background-color: #FFFFFF;\n  --main-button-color: #32323B;\n  --hover-button-color: #505060;\n  --active-button-color: #282832;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
