import React from 'react'
import { Box, Tabs, Tab, TextField } from '@mui/material'

const PlayerTabs = ({ 
  players, 
  tab, 
  setTab, 
  numberOfPlayers,
  handlePlayerNameChange 
}) => {
  return (
    <div style={{
      position: 'fixed',
      top: 65,
      left: 0,
      right: 0,
      zIndex: 1000,
      backgroundColor: '#1a1625',
      borderBottom: '1px solid rgba(255,255,255,0.1)',
      padding: '0 20px',
    }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center',
        '& .MuiTabs-root': {
          minHeight: '60px',
        },
      }}>
        <Tabs
          value={tab}
          onChange={(event, newValue) => setTab(newValue)}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          textColor="inherit"
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "#8b5cf6",
            },
            "& .MuiTab-root": {
              color: 'rgba(255,255,255,0.7)',
              minHeight: '50px',
              position: 'relative',
              '&.Mui-selected': {
                color: '#fff',
              },
              '&:hover .edit-name-input': {
                opacity: 1,
              },
            },
          }}
        >
          {players.map((player, index) => (
            <Tab 
              key={index} 
              value={index}
              label={
                <div style={{ position: 'relative', padding: '4px 0' }}>
                  {tab === index ? (
                    <TextField
                      size="small"
                      variant="standard"
                      value={player.name}
                      onChange={(e) => handlePlayerNameChange(e, index)}
                      onClick={(e) => e.stopPropagation()}
                      onDoubleClick={(e) => e.target.select()}
                      onTouchStart={(e) => e.target.select()}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === 'Tab') {
                          e.preventDefault();
                          const nextTab = (tab + 1) % numberOfPlayers;
                          setTab(nextTab);
                        }
                      }}
                      sx={{
                        width: '100px',
                        '& .MuiInput-root': {
                          color: 'white',
                          fontSize: '0.875rem',
                          '&:before, &:after': {
                            borderBottom: 'none',
                          },
                          '&:hover:not(.Mui-disabled):before': {
                            borderBottom: 'none',
                          },
                          '&.Mui-focused:after': {
                            borderBottom: 'none',
                          },
                        },
                        '& .MuiInput-input': {
                          textAlign: 'center',
                          padding: '4px 8px',
                          borderRadius: '8px',
                          transition: 'background-color 0.2s ease',
                          textTransform: 'uppercase',
                          '&:hover, &:focus': {
                            backgroundColor: 'rgba(255,255,255,0.05)',
                          },
                        },
                      }}
                    />
                  ) : (
                    <div style={{ 
                      display: 'flex', 
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: '4px'
                    }}>
                      <div style={{ 
                        color: tab === index ? '#8b5cf6' : 'rgba(255,255,255,0.7)',
                        fontWeight: tab === index ? 'bold' : 'normal',
                        fontSize: tab === index ? '1.1em' : '1em'
                      }}>
                        {player.name}
                      </div>
                      {tab === index && (
                        <div style={{
                          color: '#8b5cf6',
                          fontSize: '0.8em',
                          fontWeight: '500'
                        }}>
                          Din tur!
                        </div>
                      )}
                    </div>
                  )}
                </div>
              }
            />
          ))}
        </Tabs>
      </Box>
    </div>
  )
}

export default PlayerTabs 