import React from 'react'
import { Paper, Grid } from '@mui/material'

const ScoreTable = ({ 
  players, 
  tab, 
  minValueForBonus,
  renderCategoryRows,
  calculateScore,
  calculateTotal,
  FirstCategory,
  SecondCategory,
  gameType
}) => {
  return (
    <Paper 
      elevation={3}
      sx={{
        backgroundColor: 'rgba(255,255,255,0.05)',
        borderRadius: '16px',
        padding: '20px',
        color: 'white',
      }}
    >
      <Grid item sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '16px', gap: '4px' }} xs={12}>
        <div style={{
          color: '#8b5cf6',
          fontSize: '28px',
          fontWeight: 'bold',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          gap: '8px'
        }}>
          <span style={{ fontSize: '32px' }}>🎲</span>
          YATZY
          <span style={{ fontSize: '32px' }}>🎲</span>
        </div>
        <div style={{
          color: '#8b5cf6',
          fontSize: '20px',
          fontWeight: 'bold',
          textAlign: 'center'
        }}>
          {gameType === 'fiveDice' ? '5 Terninger' : '6 Terninger'}
        </div>
      </Grid>
      <Grid item sx={{ display: 'flex', justifyContent: 'center' }} xs={12}>
        <table style={{ width: '100%', borderCollapse: 'separate', borderSpacing: '0 8px' }}>
          <thead>
            <tr>
              <th style={{
                color: 'rgba(255,255,255,0.7)',
                fontSize: '20px',
                textAlign: 'center',
                padding: '10px 0',
              }}>
                Kategori
              </th>
              <th></th>
              <th style={{
                color: 'rgba(255,255,255,0.7)',
                fontSize: '20px',
                textAlign: 'center',
              }}>
                Score
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {renderCategoryRows(FirstCategory, 'first', true)}
            <tr>
              <td style={{
                fontWeight: 'bold',
                padding: '15px 0',
                borderTop: '1px solid rgba(255,255,255,0.1)',
              }}>
                Sum
              </td>
              <td style={{fontStyle: 'italic', color: 'rgba(255,255,255,0.7)'}}>
                (min. {minValueForBonus})
              </td>
              <td style={{
                fontWeight: 'bold',
                textAlign: 'center',
              }}>
                {calculateScore(players[tab].firstCategoryScores)}
              </td>
            </tr>
            <tr>
              <td style={{fontWeight: 'bold'}}>Bonus</td>
              <td style={{fontStyle: 'italic', color: 'rgba(255,255,255,0.7)'}}>50</td>
              <td style={{textAlign: 'center'}}>
                {calculateScore(players[tab].firstCategoryScores) >= minValueForBonus ? 50 : 0}
              </td>
            </tr>
            {renderCategoryRows(SecondCategory, 'second')}
            <tr>
              <td style={{
                fontWeight: 'bold',
                padding: '15px 0',
                borderTop: '1px solid rgba(255,255,255,0.1)',
              }}>
                Total
              </td>
              <td></td>
              <td style={{
                fontWeight: 'bold',
                textAlign: 'center',
              }}>
                {calculateTotal(players[tab])}
              </td>
            </tr>
          </tbody>
        </table>
      </Grid>
    </Paper>
  )
}

export default ScoreTable 