import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material'
import { CloseOutlined } from '@mui/icons-material'

interface Props {
    title: string
    open: boolean
    onClose: any
    content?: any
}

function SvirrensDialog(props: Props) {
    return(
        <Dialog open={props.open} onClose={props.onClose} >
            <DialogTitle>{props.title}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={props.onClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500],
                })}
                >
                <CloseOutlined />
            </IconButton>
            <DialogContent>
                {props.content}
            </DialogContent>
        </Dialog>
    )
}

export default SvirrensDialog